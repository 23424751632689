/* Global Styles */
/* Reset user agent spreadsheets */


#root .app-root {    
    min-height: 100%;
    font-family: 'Satoshi', sans-serif;
    font-size: 14px;
    line-height: 20px;
    background-color: #fff;
}

a {
    background-color: transparent;
    color: #424252;
    text-decoration: none;
}

h1, h2, h3 {
    font-family: 'Satoshi', sans-serif;
    font-weight: 400;
}

h1 {
    margin: 5% auto 20px;
    padding: 14px 10px 0;
    color: #0F1626;
    font-size: 31px;
    line-height: 46px;
    font-family: 'Stardom';
}

h2 {
    font-size: 23px;
    line-height: 1.5;
    padding-top: 0;
}

h3 {
    font-size: 23px;
    margin-top: auto;
    margin-right: auto;
    padding: 0;
}