/* Footer Styles */
footer {
    padding-top: 8%;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.footer-row {
    display: flex;
    justify-content: space-between;
    margin-top: 8%;
    margin-bottom: 8%;
}

.footer-header {
    padding-top: 10px;
    color: #323649;
    font-size: 15px;
}

.footer-subheader {
    padding-top: 5px;
    font-size: 15px;
    line-height: 25px;
}

.footer-column {
    flex: 1;
    padding-left: 10%;
    padding-right: 10%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.footer-subheader a {
    transition: color 0.3s ease;
}

.footer-subheader .fab {
    margin: 0 6px;
    font-size: 23px;
}

.footer-subheader .fab:first-child {
    margin-left: 0;
}

.footer-subheader a:hover {
    color: orange;
}

.footer-column:not(:last-child) {
    margin-right: 5%; /* Add margin between columns */
}

/* Responsive Footer */
@media (max-width: 768px) {
    .footer {
        width: 80%;
        padding: 0 5%;
    }

    .footer-row {
        flex-direction: column;
    }

    .footer-column {
        margin-bottom: 20px;
    }

    .footer-subheader a:hover {
        color: inherit;
    }
}