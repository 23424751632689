/* Secondary Content Container Styles*/
.secondary-content-section {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    max-width: 1200px;
}

.secondary-content-section h1 {
    margin-top: 10%;
    font-size: 40px;
    font-weight: bold;
}

.secondary-content-section h2 {
    padding-left: 11.5px;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: -0.5%;
}

.secondary-content-section h3 {
    font-weight: bold;
    font-size: 24px;
    margin-top: 40px;
    margin-bottom: -0.5%;
}

.secondary-content-section p {
    padding-left: 12px;
    margin-right: 90px;
    font-family: 'Satoshi', sans-serif;
    line-height: 1.75;
    font-size: 18px;
}

.secondary-content-section a {
    text-decoration: underline;
    color: #888888; 
}
  
.secondary-content-section a:hover {
text-decoration: underline;
color: #555555;
}

.secondary-content-section .photo-credits {
    text-align: center;
    margin-top: 0px;
}

/* About Me Content Styles */
.headshot {
    background-image: url('../../public/assets/images/headshot.webp');
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    margin-right: 20px;
}

/* Cryptocurrency Content Styles */
.header-image-crypto {
    background-image: url('../../public/assets/images/traxer-cryptocurrency.jpg');
    background-size: cover;
    background-position: center;
    margin-left: 12px;
    width: 100%;
    height: 400px;
}

/* NBA Content Styles */
.header-image-nba {
    background-image: url('../../public/assets/images/tim-hart-nba.jpg');
    background-size: cover;
    background-position: 0 70%;
    margin-left: 12px;
    width: 100%;
    height: 400px;
}

/* EV Content Styles */
.header-image-ev {
    background-image: url('../../public/assets/images/sports-betting.jpeg');
    background-size: cover;
    margin-left: 12px;
    width: 100%;
    height: 400px;
    margin-bottom: 60px;
}

/* Personal Portfolio Styles */
.portfolio ul {
    list-style-type: disc;
    padding-left: 30px;
    margin-right: 90px;
    font-family: 'Satoshi', sans-serif;
    line-height: 1.75;
    font-size: 18px;
}

.portfolio ul li {
    padding-left: 0;
    margin-bottom: 10px;
}
