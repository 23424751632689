/* Nav Styles */
nav {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Satoshi', sans-serif;
    font-size: 25px;
}

.navbar {
    display: flex;
    position: fixed;
    top: 0;
    justify-content: center;
    align-items: center;
    margin-bottom: -6%;
    padding: 2px 8px;
    z-index: 1000;
    transition: border-radius 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease, backdrop-filter 0.3s ease;
    height: 50px;
}

.navbar.scrolled {
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.nav-link {
    padding: 10px 20px;
    transition: 0.3s ease;
}

.nav-link:hover {
    color: orange;
}

/* Responsive Navbar */
@media (max-width: 768px) {
    .navbar {
        position: relative;
        margin-bottom: -50px;
    }

    .navbar.scrolled {
        border-radius: 0;
        box-shadow: none;
        background-color: inherit;
        backdrop-filter: none;
    }

    .nav-link:hover {
        color: inherit;
    }
}