/* Work Content Styles */
.content-container {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    max-width: 1200px;
}

.container-header {
    position: relative;
    display: inline-block;
    font-weight: bold;
    z-index: 0;
}

.container-header::after {
    content: '';
    position: absolute;
    left: 20px;
    right: 5px;
    bottom: 18px;
    height: 10px; 
    background-color: orange;
    z-index: -1;
    transform: skew(-20deg) translateY(100%);
    transform-origin: bottom left;
}

/* Introduction Styles */
header {
    width: 100%;
    min-height: 100vh;
    background-color: #E5E4E2;
    padding-top: 50px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: left;
    box-sizing: border-box;
}

.introduction {
    margin-left: 10%;
    max-width: 60%;
    z-index: 0;
}

.greeting {
    font-family: 'Stardom';
    font-size: 70px;
    position: relative;
}

.fullname {
    position: relative;
    display: inline-block;
}

.fullname::after {
    content: '';
    position: absolute;
    left: 20px;
    right: 9px;
    bottom: 10px;
    height: 20px;
    background-color: orange;
    z-index: -1;
    transform: skew(-20deg) translateY(100%);
    transform-origin: bottom left;
}

.current-title,
.title-description {
    padding-left: 12px;
}

.current-title {
    font-size: 30px;
}

.title-description {
    font-size: 25px;
}

/* Experience Styles */
.timeline {
    position: relative;
    padding: 10px 0;
}

.timeline::before {
    content: '';
    position: absolute;
    width: 2px;
    background-color: #e0e0e0;
    top: 0;
    bottom: 0;
    left: 20px;
}

.timeline-item {
    display: flex;
    margin: 20px 0;
    padding-left: 40px;
    position: relative;
}

.timeline-item .timeline-icon {
    position: absolute;
    top: 0;
    left: 16px;
    margin-top: 10px;
    width: 12px;
    height: 12px;
    background-color: #555;
    border-radius: 50%;
}

.timeline-item.previous .timeline-icon {
    background-color: #cccccc;
}

.timeline-item .present-badge {
    background-color: #e0e0e0;
    font-family: Serif, sans-serif;
    border-radius: 12px;
    padding: 2px 8px;
    font-size: 12px;
    margin-left: 10px;
}

.timeline-item .timeline-content {
    padding-left: 20px;
    padding-top: 4px;
}

.timeline-item .job-title {
    font-family: 'Satoshi', sans-serif;
    color: #323649;
    line-height: 1.5;
    font-size: 19px;
    font-weight: bold;
    margin: 0;
    display: flex;
    align-items: center;
}

.timeline-item .company {
    font-size: 15px;
    font-family: 'Satoshi', sans-serif;
    color: #9FA0AA;
    margin: 4px 0 0;
}

.resume-button {
    margin-top: 20px;
}

.resume-link {
    padding: 10px;
    text-decoration: none;
    font-size: 15px;
    font-weight: bold;
    color: #323649;
    transition: 0.3s; background-color: 0.3s;
}

.resume-link:hover {
    color: white;
    background-color: orange;
    border-radius: 5px;
}

/* Skill Styles */
.skills .row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.skills .column {
    flex: 1 1 120px;
    padding: 10px;
    text-align: center;
}

.skill-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    transition: transform 0.3s ease;
}

.skill-item:hover {
    transform: scale(1.05);
}

.skill-item img {
    width: 65px;
    height: 65px;
    margin-bottom: 10px;
}

.skill-item h2 {
    font-size: 1.2em;
    margin: 0;
}

/* Project Styles */
.projects {
    margin-top: 0;
    padding-top: 0;
}

.projects .row {
    display: flex;
    flex-wrap: wrap;
}

.projects .column {
    display: flex;
    flex: 1;
    padding: 10px;
    box-sizing: border-box;
    min-width: 300px;
    flex-direction: column;
}

.project-item {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.project-image-container {
    position: relative;
    width: 100%;
    height: 400px; 
    background-color: #e0e0e0;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; 
}

.project-image-container .image {
    position: relative;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; 
    margin-bottom: 15px;
    transition: transform 0.3s ease;
}

.project-image-container:hover .image {
    transform: scale(1.2);
}

.crypto { background-image: url('../../public/assets/images/crypto.png'); height: 200px; }
.nba { background-image: url('../../public/assets//images/nba-proj-model.png'); height: 200px; }
.ev { background-image: url('../../public/assets//images/positive-ev-tracker.png'); height: 200px; }
.portfolio { background-image: url('../../public/assets//images/personal-portfolio.png'); height: 250px; }

.project-title {
    display: flex;
    flex-direction: column;
}

.project-info {
    text-align: left;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 10%;
}

.project-skills-container {
    display: flex;
    flex-wrap: wrap;
}

.project-skills {
    display: inline-block;
    background-color: #f0f0f0;
    color: black;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 1em; 
    margin: 0 10px 10px 0;
}

.project-description {
    font-size: 21px;
    flex: 1;
    display: flex;
}

.project-description.one {
    align-items: flex-end;
}

.project-description.two {
    align-items: flex-start;
}


/* Responsive Styles*/
@media (max-width: 768px) {
    .content-container {
        width: 80%;
        padding: 0 5%;
    }

    .container-header {
        margin-left: -12px;
    }

    .introduction {
        margin-left: 5%;
        max-width: 90%;
    }

    .greeting {
        font-size: 50px;
    }

    .current-title {
        font-size: 25px;
    }

    .title-description {
        font-size: 20px;
    }

    .fullname::after {
        right: 7px;
        bottom: 15px;
    }

    .timeline-item {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 0;
    }

    .timeline-item .timeline-content {
        padding-left: 50px;
        padding-top: 2px;

    }

    .project-item {
        flex-direction: column;
    }

    .project-image-container {
        height: auto;
    }

    .skills .column,
    .projects .column {
        flex: 1 1 auto;
        padding: 10px 0;
    }

    .resume-link:hover {
        color: inherit;
        background-color: inherit;
        border-radius: 0px;
    }
}